@import "~semantic-ui-css/semantic.min.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind colors;
@import "helpers/margins";
@import "helpers/paddings";
@import "helpers/heights";
@import "helpers/headings";
/*
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.app-content {
  background-color: #fff;
  min-height: 100vh;
  height: 100%;
}

.card-padding {
  margin: 18px;
  padding: 24px;
}

.mobile-padding {
  padding: 10px;
}

.ant-picker {
  display: flex;
}

.ncr-form {
  padding: 24px;
}

*/
